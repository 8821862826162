<template>
  <div>
    <div class="apk-info">
      <el-image :src="tableData.appIcon">
        <div slot="error">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <div>
        应用名称：{{ apkData.title }}<br /><br />
        应用包名：{{ apkData.packageName }}
      </div>
    </div>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      rowKey="releaseId"
    >
      <template slot-scope="{ row }" slot="versionName">
        <span v-if="row.versionName">
          {{ row.versionName }} -
          {{ row.createTime && row.createTime.split(" ")[0].replace(/-/g, "") }}
        </span>
      </template>
      <template slot-scope="{ row }" slot="releaseType">{{
        row.releaseType | typeDesc(releaseTypeList)
      }}</template>
      <template slot-scope="{ row }" slot="releaseStatus">
        <el-tag size="small" :type="row.releaseStatus | tagType(appStatusList)">
          {{ row.releaseStatus | typeDesc(appStatusList) }}
        </el-tag>
      </template>
      <el-table-column label="操作" width="100px" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleOperate('view', row)"
            >查看</el-button
          >

          <el-popconfirm
            v-has="'historyBack'"
            :title="row | backTitle"
            @confirm="handleOperate('back', row)"
          >
            <el-button
              v-if="[1004, 1005].includes(row.releaseStatus)"
              type="text"
              class="ml10"
              slot="reference"
              :disabled="row.sourceType == 0"
              >回退</el-button
            >
            <el-button
              v-if="[1002, 1003].includes(row.releaseStatus)"
              type="text"
              class="ml10"
              slot="reference"
              :disabled="row.releaseStatus == 1002 && row.releaseType == 3"
              >{{ row.releaseStatus == 1003 ? "下架" : "上架" }}</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </PageTable>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import { appStatusList, releaseTypeList } from "@/common/constant/constant.js";
import { getHistoryList } from "./indexServe.js";
export default {
  components: { PageTable },
  props: {
    apkData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      appStatusList,
      releaseTypeList,
      tableData: {
        list: [],
      },
      columns: [
        { slot: "versionName", label: "版本号" },
        { key: "createTime", label: "上架时间" },
        { key: "fileSize", label: "包大小" },
        { slot: "releaseType", label: "上架类型" },
        { slot: "releaseStatus", label: "状态" },
      ],
    };
  },
  mounted() {
    this.getTableList();
  },
  filters: {
    backTitle(row) {
      let desc = `确认回退到${row.versionName}版本吗？`;
      if (![1004, 1005].includes(row.releaseStatus)) {
        desc = `确定${row.releaseStatus == 1003 ? "下架" : "上架"}吗？`;
      }
      return desc;
    },
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      if (operationType === "view") {
        this.$emit("view", row);
      } else if (operationType === "back") {
        this.$emit("appBack", row);
      }
    },
    // 获取列表
    async getTableList(params) {
      const appInfoId = this.apkData.id;
      const releaseId = this.apkData.releaseId;
      if (appInfoId) {
        const res = await getHistoryList({
          page: 1,
          size: 10,
          appInfoId,
          releaseId,
          ...params,
        });
        if (res && res.code === 0) {
          this.tableData = res.data;
        }
      }
    },
  },
  watch: {
    apkData() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.apk-info {
  display: flex;
  font-size: 16px;
  align-items: center;
  /deep/.el-image {
    width: 80px;
    height: 80px;
    margin: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    line-height: 80px;
    text-align: center;
    font-size: 20px;
    img {
      width: 100%;
    }
  }
}
</style>
